import React from "react"
import PropTypes from "prop-types"
import IntroSection from "./intro-section"
import ClientsSection from "./clients-section"
import ProjectsSection from "./projects-section"
import ContactSection from "../../common/contact/contact-section"
import Theme from "../../../theme/theme"

const ProjectsContent = ({ pageContext }) => {
  return (
    <>
      <IntroSection projectsData={pageContext.pageData} />
      <ClientsSection
        projects={pageContext.projects}
        projectsSlug={pageContext.pageData.slug}
      />
      <ProjectsSection
        projects={pageContext.projects}
        categories={pageContext.categories}
        projectsSlug={pageContext.pageData.slug}
      />
      <ContactSection
        content={pageContext.pageData.contact}
        sectionColor={Theme.colors.gray2}
        contactPageSlug={pageContext.contactPageSlug}
      />
    </>
  )
}

ProjectsContent.propTypes = {
  pageContext: PropTypes.object,
}

export default ProjectsContent
