import React, { useContext, useState } from "react"
import styled from "@emotion/styled"
import PropTypes from "prop-types"
import { MainContext } from "../../../../context/main-context"
import SingleProject from "./single-project"
import { TRANSLATIONS } from "../../../../utils/translations"

const ProjectsListElement = styled.div`
  width: 100%;
  max-width: 160rem;
  margin: 0 auto;
`

const FilterTitle = styled.p`
  max-width: 86rem;
  color: #000;
  font-family: "darker_grotesquebold", sans-serif;
  font-size: 4.8rem;
  line-height: 6rem;
  margin-bottom: 8rem;

  @media (min-width: ${props => props.theme.devices.desktopLarge}) {
    font-size: 6.8rem;
    line-height: 8rem;
    margin-bottom: 12rem;
  }
`

const ProjectCategories = styled.ul`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 7rem;
`

const SingleCategory = styled.li`
  margin-right: 8rem;
  margin-bottom: 5rem;

  @media (min-width: ${props => props.theme.devices.desktopLarge}) {
    margin-right: 15rem;
  }
`

const FilterButton = styled.button`
  position: relative;
  font-size: 2.8rem;
  background: none;
  border: none;
  cursor: pointer;
  padding-bottom: 0.5rem;
  transition: 200ms linear;
  border-bottom: 0.3rem solid #fff;

  @media (min-width: ${props => props.theme.devices.desktopLarge}) {
    font-size: 3.6rem;
  }

  ${props =>
    props.$isActive &&
    `
    border-bottom-color:#000000;
  `}

  span {
    position: absolute;
    top: -1.5rem;
    right: -3.7rem;
    width: 3.7rem;
    height: 3.7rem;
    background: #000;
    font-size: 2rem;
    font-family: "darker_grotesquebold", sans-serif;
    color: #fff;
    border-radius: 50%;
    padding-top: 0.6rem;
  }
`

const Projects = styled.div`
  display: grid;
  grid-row-gap: 10rem;
  justify-content: center;
  grid-template-columns: repeat(auto-fit, minmax(20rem, 100%));

  @media (min-width: ${props => props.theme.devices.mobile}) {
    grid-column-gap: 10%;
    grid-template-columns: repeat(auto-fit, minmax(20rem, 45%));
  }

  @media (min-width: ${props => props.theme.devices.tablet}) {
    grid-template-columns: repeat(auto-fit, minmax(35rem, 45%));
  }

  @media (min-width: ${props => props.theme.devices.desktopLarge}) {
    justify-content: space-between;
    grid-template-columns: repeat(auto-fit, minmax(20rem, 60rem));
  }
`

const ProjectsList = ({ categories = [], projects, projectsSlug }) => {
  const { setCurrentPage } = useContext(MainContext)
  const [filteredProjects, setFilteredProjects] = useState(projects)
  const [filtersIndex, setFiltersIndex] = useState(0)

  const filterProjects = (filter, index) => {
    setFiltersIndex(index)

    if (index === 0) {
      setCurrentPage(null)

      return setFilteredProjects(projects)
    }

    const filteredArray = projects.filter(
      ({ node }) => node.type && node.type.includes(filter),
    )

    setCurrentPage(filter)
    setFilteredProjects(filteredArray)
  }

  return (
    <ProjectsListElement>
      <FilterTitle>
        {TRANSLATIONS[process.env.GATSBY_CONTENTFUL_LOCALE].filterTitle}
      </FilterTitle>
      <ProjectCategories>
        <SingleCategory>
          <FilterButton
            onClick={() => filterProjects(null, 0)}
            $isActive={0 === filtersIndex}
          >
            {TRANSLATIONS[process.env.GATSBY_CONTENTFUL_LOCALE].all}{" "}
            <span>{projects.length}</span>
          </FilterButton>
        </SingleCategory>
        {categories?.map((category, index) => (
          <SingleCategory key={index}>
            <FilterButton
              onClick={() => filterProjects(category.name, index + 1)}
              $isActive={index + 1 === filtersIndex}
            >
              {category.name} <span>{category.number}</span>
            </FilterButton>
          </SingleCategory>
        ))}
      </ProjectCategories>
      {filteredProjects && (
        <Projects>
          {filteredProjects.map(({ node }) => (
            <SingleProject
              project={node}
              key={node.contentful_id}
              projectsSlug={projectsSlug}
            />
          ))}
        </Projects>
      )}
    </ProjectsListElement>
  )
}

ProjectsList.propTypes = {
  categories: PropTypes.array,
  projects: PropTypes.array,
  projectsSlug: PropTypes.string,
}

export default ProjectsList
