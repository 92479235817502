import React from "react"
import styled from "@emotion/styled"
import PropTypes from "prop-types"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import GatsbyImage from "../../../common/gatsby-image"
import Link from "../../../common/link"

const SingleProjectElement = styled.article`
  position: relative;
`

const SingleProjectContent = styled.div`
  position: relative;
  margin: 0 auto;
  border-bottom-right-radius: 8rem;
`

const StyledGatsbyImage = styled(GatsbyImage)`
  overflow: hidden;
  height: 48rem;
  border-bottom-right-radius: 8rem;
  transform: translateZ(0);

  @media (min-width: ${props => props.theme.devices.desktop}) {
    height: 68rem;
  }
`

const ProjectTitle = styled.h2`
  color: #989898;
  font-size: 2.3rem;
  font-family: "darker_grotesquebold", sans-serif;
  margin: 2rem 0 0;

  @media (min-width: ${props => props.theme.devices.desktopMedium}) {
    font-size: 3rem;
  }

  @media (min-width: ${props => props.theme.devices.desktopLarge}) {
    font-size: 3.6rem;
  }
`

const ProjectDescription = styled.div`
  color: #000;
  font-size: 3.5rem;
  line-height: 4.5rem;

  @media (min-width: ${props => props.theme.devices.desktopMedium}) {
    font-size: 4.5rem;
    line-height: 5.5rem;
  }

  @media (min-width: ${props => props.theme.devices.desktopLarge}) {
    font-size: 6rem;
    line-height: 8rem;
  }
`

const StyledLink = styled(Link)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
`

const SingleProject = ({ project, projectsSlug }) => {
  if (!project) return

  return (
    <SingleProjectElement>
      <SingleProjectContent>
        {project.mainImage && (
          <StyledGatsbyImage image={project.mainImage.gatsbyImageData} />
        )}
        <ProjectTitle>{project.title}</ProjectTitle>
        {project.shortDescription && (
          <ProjectDescription>
            {documentToReactComponents(
              JSON.parse(project.shortDescription.raw),
            )}
          </ProjectDescription>
        )}
      </SingleProjectContent>
      <StyledLink url={`/${projectsSlug}/${project.slug}/`} />
    </SingleProjectElement>
  )
}

SingleProject.propTypes = {
  project: PropTypes.object,
  projectsSlug: PropTypes.string,
}

export default SingleProject
