import React from "react"
import styled from "@emotion/styled"
import PropTypes from "prop-types"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import Section from "../../common/section"
import Shape from "../../svg/shape"
import Theme from "../../../theme/theme"
import ScrollButton from "../../common/scroll-button"

const StyledSection = styled(Section)`
  position: relative;
  flex-direction: column;
  padding-bottom: 0 !important;
  margin-bottom: 0 !important;
`

const IntroText = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 5rem;
  padding-top: 11rem;
  z-index: 1;

  @media (min-width: ${props => props.theme.devices.desktop}) {
    flex-direction: row;
  }

  > div {
    @media (max-width: ${props => props.theme.devices.desktop}) {
      font-size: 3rem;
      line-height: 3.4rem;
      margin-top: 3rem;
    }

    @media (min-width: ${props => props.theme.devices.desktop}) {
      max-width: 63rem;
      text-align: right;
      margin-left: auto;
    }
  }
`

const H1 = styled.h1`
  position: relative;
  align-items: center;
  flex-direction: column;
  font-size: 4rem;
  line-height: 4.5rem;

  @media (min-width: ${props => props.theme.devices.desktop}) {
    font-size: 8rem;
    line-height: 8rem;
  }

  @media (min-width: ${props => props.theme.devices.desktopLarge}) {
    font-size: 10rem;
    line-height: 12rem;
  }
`

const StyledScrollButton = styled(ScrollButton)`
  position: relative;
  font-size: 2.8rem;
  line-height: 3.2rem;
  font-family: "darker_grotesquebold", sans-serif;
  text-align: left;
  color: inherit;
  background: none;
  border: 0;
  margin-top: 0;
  padding-right: 12rem;
  cursor: pointer;

  @media (min-width: ${props => props.theme.devices.desktopLarge}) {
    margin-top: 5rem;
  }

  svg {
    position: absolute;
    top: 50%;
    left: 0;
    margin-top: -4rem;
  }
`

const BgShape1 = styled(Shape)`
  position: absolute;
  top: 17%;
  left: 40%;
  transform: rotate(180deg);
  z-index: -1;
`
const BgShape2 = styled(Shape)`
  position: absolute;
  top: 41%;
  right: -1%;
  z-index: -1;
`

const IntroSection = ({ projectsData }) => {
  return (
    <StyledSection
      pageColor={Theme.colors.invertedText}
      textColor={Theme.colors.text}
      invertedTextColor={Theme.colors.invertedText}
      threshold={0.4}
    >
      <BgShape1 isMainColor={true} delay={300} />
      <BgShape2 delay={500} />
      <IntroText>
        <H1>{projectsData.title}</H1>
        <div>
          {documentToReactComponents(JSON.parse(projectsData.introText.raw))}
        </div>
      </IntroText>
      <StyledScrollButton sectionId="projects-section" />
    </StyledSection>
  )
}

IntroSection.propTypes = {
  projectsData: PropTypes.object,
}

export default IntroSection
