import React from "react"
import styled from "@emotion/styled"
import PropTypes from "prop-types"
import Section from "../../common/section"
import ProjectsList from "../projects/projects/projects-list"
import Theme from "../../../theme/theme"

const StyledSection = styled(Section)`
  position: relative;
  min-height: 100vh;
  padding-bottom: 10rem;
`

const ProjectsSection = ({ categories, projects, projectsSlug }) => {
  return (
    <StyledSection
      pageColor={Theme.colors.invertedText}
      textColor={Theme.colors.text}
      invertedTextColor={Theme.colors.invertedText}
      sectionId="projects-section"
    >
      <ProjectsList
        projects={projects}
        categories={categories}
        projectsSlug={projectsSlug}
      />
    </StyledSection>
  )
}

ProjectsSection.propTypes = {
  projectsSlug: PropTypes.string,
  categories: PropTypes.array,
  projects: PropTypes.array,
}

export default ProjectsSection
