import React from "react"
import Viewport from "../../components/common/viewport"
import ProjectsContent from "../content/projects/projects-content"
import SEO from "../seo"

const ProjectsPage = props => {
  const page = props.pageContext.pageData

  if (!page) return

  return (
    <Viewport>
      <ProjectsContent pageContext={props.pageContext} />
    </Viewport>
  )
}

export default ProjectsPage

export const Head = ({ location, pageContext }) => (
  <SEO
    pathname={location.pathname}
    title={pageContext.pageData.title}
    description={pageContext.pageData.seoDescription}
    keywords={pageContext.pageData.seoKeywords}
  />
)
