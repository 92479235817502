import React from "react"
import styled from "@emotion/styled"
import Section from "../../common/section"
import ClientsContainer from "../../common/clients/clients-container"
import Theme from "../../../theme/theme"
import PropTypes from "prop-types"

const StyledSection = styled(Section)`
  overflow: hidden;
  margin-top: 10rem;
`

const ClientsSection = ({ projects, projectsSlug }) => {
  return (
    <StyledSection
      pageColor={Theme.colors.invertedText}
      textColor={Theme.colors.text}
      threshold={0.9}
    >
      <ClientsContainer projects={projects} projectsSlug={projectsSlug} />
    </StyledSection>
  )
}

ClientsSection.propTypes = {
  projects: PropTypes.array,
  projectsSlug: PropTypes.string,
}

export default ClientsSection
